import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { baseURL, getHistory } from "../../api/api";

function History({ env }) {
  const [history, setHistory] = useState(null);

  const fetchLogs = async () => {
    const lo = await getHistory(env);
    setHistory(lo);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Bridging History
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Request Id</TableCell>
              <TableCell>Source Currency</TableCell>
              <TableCell>Destination Currency</TableCell>
              <TableCell>Recipient Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{new Date(row.createdAt).toUTCString()}</TableCell>
                <TableCell
                  onClick={() =>
                    window.open(
                      `${baseURL}/wallet/status?txId=${row.txId}&requestId=${row.requestId}`
                    )
                  }
                  style={{ color: "#00f", cursor: "pointer" }}
                >
                  {row.requestId}
                </TableCell>
                <TableCell>
                  {row.fromAmount} {row.sourceCurrency} - {row.sourceChain}
                </TableCell>
                <TableCell>
                  {row.toAmount} {row.destinationCurrency} -{" "}
                  {row.destinationChain}
                </TableCell>
                <TableCell>
                  {row.recipientAddress.substring(0, 7)}...
                  {row.recipientAddress.substring(
                    row.recipientAddress.length - 5,
                    row.recipientAddress.length
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

export default History;
