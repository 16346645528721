import axios from "axios";

// For local
export const baseURL = "/api/v1";

const axiosInstance = axios.create({
  baseURL,
});

export const getWallets = async (env) => {
  const resp = await axiosInstance.get(`/wallet?env=${env}`);

  return resp.data;
};

export const getConfiguration = async () => {
  const resp = await axiosInstance.get("/wallet/configuration");

  return resp.data.envVars;
};

export const updateConfiguration = async (updatedVars) => {
  const resp = await axiosInstance.put("/wallet/configuration", {
    updatedVars,
  });

  return resp.data.success;
};

export const getEnvs = async () => {
  const resp = await axiosInstance.get("/wallet/env");

  return resp.data.envs;
};

export const addEnv = async (env) => {
  const resp = await axiosInstance.post("/wallet/env", { env });

  return resp.data.wallet;
};

export const replacePrimaryWallet = async (primaryPk = null, env) => {
  const resp = await axiosInstance.put("/wallet/primary/replace", {
    primaryPk,
    env,
  });

  return resp.data.success;
};

export const exportWallet = async (id) => {
  const resp = await axiosInstance.post(`/wallet/export/${id}`);

  return resp.data.privateKey;
};

export const generateSecondaryWallet = async (
  num,
  secondaryPks = null,
  env
) => {
  const resp = await axiosInstance.put("/wallet/secondary/generate", {
    num,
    secondaryPks,
    env,
  });

  return resp.data.success;
};

export const importSecondaryWallets = async (csvData, env) => {
  const resp = await axiosInstance.put("/wallet/secondary/import", {
    csvData,
    env,
  });

  return resp.data.success;
};

export const removeSecondaryWallet = async (id) => {
  const resp = await axiosInstance.delete(`/wallet/secondary/${id}`);

  return resp.data.success;
};

export const hideSecondaryWallet = async (id) => {
  const resp = await axiosInstance.put(`/wallet/secondary/${id}`, {
    isHidden: true,
  });

  return resp.data.success;
};

export const unhideSecondaryWallet = async (id) => {
  const resp = await axiosInstance.put(`/wallet/secondary/${id}`, {
    isHidden: false,
  });

  return resp.data.success;
};

export const updateSecondaryWalletGroups = async (id, tags) => {
  const resp = await axiosInstance.put(`/wallet/secondary/${id}`, {
    tags,
  });

  return resp.data.success;
};

export const bulkAddTag = async (walletIds, newTag) => {
  const resp = await axiosInstance.put(`/wallet/secondary/tags`, {
    walletIds,
    newTag,
  });

  return resp.data.success;
};

export const distributeFunds = async (
  addresses,
  amount,
  maxAmount,
  amountDeviation,
  period,
  maxPeriod,
  deviation,
  limit,
  speed,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType,
  isGlobal,
  tokenAmounts,
  env
) => {
  const resp = await axiosInstance.post(`/wallet/job`, {
    addresses,
    amount,
    maxAmount,
    amountDeviation,
    period,
    maxPeriod,
    deviation,
    limit,
    speed,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
    isGlobal,
    tokenAmounts,
    env,
    type: "Fund Wallets",
    module: "walletModule",
  });

  return resp.data.success;
};

export const distributeToWallets = async (
  tokenAddress,
  maxHolding,
  period,
  maxPeriod,
  deviation,
  limit,
  speed,
  csvData,
  env
) => {
  const resp = await axiosInstance.post(`/wallet/job`, {
    tokenAddress,
    maxHolding,
    period,
    maxPeriod,
    deviation,
    limit,
    speed,
    csvData,
    env,
    type: "Distribute",
    module: "distributionModule",
  });

  return resp.data.success;
};

export const collectFunds = async (
  address,
  percentAmount,
  walletIds,
  limit,
  speed,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType,
  env
) => {
  const resp = await axiosInstance.post(`/wallet/job`, {
    address,
    percentAmount,
    walletIds,
    limit,
    speed,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
    env,
    type: "Collect",
    module: "batchModule",
  });

  return resp.data.success;
};

export const sendToken = async (
  address,
  percentAmount,
  walletIds,
  tokenAddress,
  limit,
  speed,
  env
) => {
  const resp = await axiosInstance.post(`/wallet/job`, {
    address,
    percentAmount,
    walletIds,
    tokenAddress,
    limit,
    speed,
    env,
    type: "Token Send",
    module: "batchModule",
  });

  return resp.data.success;
};

export const accumulate = async (
  walletIds,
  tokenAddress,
  slippage,
  period,
  maxPeriod,
  deviation,
  amount,
  maxAmount,
  amountDeviation,
  maxHolding,
  retentionAmount,
  isPrioritized,
  limit,
  speed,
  platforms,
  env
) => {
  // - Deviation in %
  // - Period in seconds
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/wallet/job", {
    walletIds,
    tokenAddress,
    slippage,
    period,
    maxPeriod,
    deviation,
    amount,
    maxAmount,
    amountDeviation,
    maxHolding,
    retentionAmount,
    isPrioritized,
    limit,
    speed,
    platforms,
    env,
    type: "Accumulate",
    module: "accumulationModule",
  });

  return resp.data.success;
};

export const sellAllToken = async (
  walletIds,
  tokenAddress,
  slippage,
  percentAmount,
  limit,
  speed,
  platforms,
  env
) => {
  const resp = await axiosInstance.post(`/wallet/job`, {
    walletIds,
    tokenAddress,
    slippage,
    percentAmount,
    limit,
    speed,
    platforms,
    env,
    type: "Sell All",
    module: "liquidationModule",
  });

  return resp.data.success;
};

export const approvalSchedule = async (
  walletIds,
  tokenAddress,
  spenderAddress,
  period,
  maxPeriod,
  deviation,
  amount,
  limit,
  speed,
  env
) => {
  // - Deviation in %
  // - Period in seconds
  const resp = await axiosInstance.post("/wallet/job", {
    walletIds,
    tokenAddress,
    spenderAddress,
    period,
    maxPeriod,
    deviation,
    amount,
    limit,
    speed,
    env,
    type: "Approval",
    module: "liquidationModule",
  });

  return resp.data.success;
};

export const timeLiquidate = async (
  walletIds,
  tokenAddress,
  slippage,
  period,
  maxPeriod,
  deviation,
  amount,
  maxAmount,
  amountDeviation,
  limit,
  speed,
  platforms,
  env
) => {
  // - Deviation in %
  // - Period in seconds
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/wallet/job", {
    walletIds,
    tokenAddress,
    slippage,
    period,
    maxPeriod,
    deviation,
    amount,
    maxAmount,
    amountDeviation,
    limit,
    speed,
    platforms,
    env,
    type: "Time Liquidation",
    module: "liquidationModule",
  });

  return resp.data.success;
};

export const marketLiquidate = async (
  env,
  walletIds,
  tokenAddress,
  pairAddress,
  slippage,
  threshold,
  percentAmount,
  limit,
  speed,
  platforms,
  type = "Market Liquidation",
  period = 0,
  maxPeriod = 0,
  deviation = 0,
  amount = 0,
  maxAmount = 0,
  amountDeviation = 0,
  prevBlockNum = 0,
  numOfLiquidations = 0,
  priorities = []
) => {
  // - percentAmount in %
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/wallet/job", {
    walletIds,
    tokenAddress,
    pairAddress,
    slippage,
    threshold,
    percentAmount,
    limit,
    speed,
    platforms,
    type,
    period,
    maxPeriod,
    deviation,
    amount,
    maxAmount,
    amountDeviation,
    prevBlockNum,
    numOfLiquidations,
    priorities,
    env,
    module: "liquidationModule",
  });

  return resp.data.success;
};

export const scheduleWalletSwap = async (
  tokenAddress,
  slippage,
  gasPriceLimit,
  period,
  maxPeriod,
  deviation,
  amount,
  maxAmount,
  amountDeviation,
  sellerIds,
  buyerIds,
  retentionAmount,
  limit,
  speed,
  newBuyerIds,
  amount2,
  maxAmount2,
  deviation2,
  delay2,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType,
  platforms,
  env
) => {
  // - Deviation in %
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/wallet/job", {
    tokenAddress,
    slippage,
    gasPriceLimit,
    period,
    maxPeriod,
    deviation,
    amount,
    maxAmount,
    amountDeviation,
    sellerIds,
    buyerIds,
    retentionAmount,
    limit,
    speed,
    newBuyerIds,
    amount2,
    maxAmount2,
    deviation2,
    delay2,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
    platforms,
    env,
    type: "Swap",
    module: "swapModule",
  });

  return resp.data.success;
};

export const swapLaunch = async (
  tokenAddress,
  slippage,
  blockWait,
  blockTime,
  period,
  maxPeriod,
  deviation,
  amount,
  maxAmount,
  amountDeviation,
  amountBuy,
  maxAmountBuy,
  amountDeviationBuy,
  sellerIds,
  buyerIds,
  limit,
  speed,
  newBuyerIds,
  amount2,
  maxAmount2,
  deviation2,
  delay2,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType,
  priorityFee,
  addOnFee,
  swapGasLimit,
  retries,
  gasLimitIncrement,
  minNumSellers,
  maxNumSellers,
  minNumBuyers,
  maxNumBuyers,
  isBundleShuffle,
  gasPriceLimit,
  env
) => {
  const resp = await axiosInstance.post("/wallet/job", {
    tokenAddress,
    slippage,
    blockWait,
    blockTime,
    period,
    maxPeriod,
    deviation,
    amount,
    maxAmount,
    amountDeviation,
    amountBuy,
    maxAmountBuy,
    amountDeviationBuy,
    sellerIds,
    buyerIds,
    limit,
    speed,
    newBuyerIds,
    amount2,
    maxAmount2,
    deviation2,
    delay2,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
    priorityFee,
    addOnFee,
    swapGasLimit,
    retries,
    gasLimitIncrement,
    minNumSellers,
    maxNumSellers,
    minNumBuyers,
    maxNumBuyers,
    isBundleShuffle,
    gasPriceLimit,
    env,
    type: "Bundling Swap",
    module: "swapModule",
  });

  return resp.data.success;
};

export const swapAutoLaunch = async (
  tokenAddress,
  slippage,
  blockWait,
  blockTime,
  period,
  maxPeriod,
  deviation,
  amount,
  maxAmount,
  amountDeviation,
  amountBuy,
  maxAmountBuy,
  amountDeviationBuy,
  limit,
  speed,
  priorityFee,
  addOnFee,
  swapGasLimit,
  retries,
  gasLimitIncrement,
  minNumSellers,
  maxNumSellers,
  minNumBuyers,
  maxNumBuyers,
  numOfBundles,
  sellerCriteria,
  buyerCriteria,
  enableDuplicates,
  isTokenRangeSeller,
  isETHRangeSeller,
  isTokenRangeBuyer,
  isETHRangeBuyer,
  minTokenRangeSeller,
  maxTokenRangeSeller,
  minEthRangeSeller,
  maxEthRangeSeller,
  minTokenRangeBuyer,
  maxTokenRangeBuyer,
  minEthRangeBuyer,
  maxEthRangeBuyer,
  sellerIds,
  buyerIds,
  isBundleShuffle,
  gasPriceLimit,
  env
) => {
  const resp = await axiosInstance.post("/wallet/job", {
    tokenAddress,
    slippage,
    blockWait,
    blockTime,
    period,
    maxPeriod,
    deviation,
    amount,
    maxAmount,
    amountDeviation,
    amountBuy,
    maxAmountBuy,
    amountDeviationBuy,
    limit,
    speed,
    priorityFee,
    addOnFee,
    swapGasLimit,
    retries,
    gasLimitIncrement,
    minNumSellers,
    maxNumSellers,
    minNumBuyers,
    maxNumBuyers,
    numOfBundles,
    sellerCriteria,
    buyerCriteria,
    enableDuplicates,
    isTokenRangeSeller,
    isETHRangeSeller,
    isTokenRangeBuyer,
    isETHRangeBuyer,
    minTokenRangeSeller,
    maxTokenRangeSeller,
    minEthRangeSeller,
    maxEthRangeSeller,
    minTokenRangeBuyer,
    maxTokenRangeBuyer,
    minEthRangeBuyer,
    maxEthRangeBuyer,
    sellerIds,
    buyerIds,
    isBundleShuffle,
    gasPriceLimit,
    env,
    type: "Auto Bundling Swap",
    module: "swapModule",
  });

  return resp.data.success;
};

export const nonceSwap = async (
  period,
  maxPeriod,
  deviation,
  gasPriceLimit,
  swapGasLimit,
  blockWait,
  blockTime,
  priorityFee,
  addOnFee,
  tokenAddresses,
  ethPercent,
  tokenPercent,
  walletIds,
  retries,
  env,
  toApprove,
  tokenAddress,
  spenderAddress,
  amount,
  speed
) => {
  const resp = await axiosInstance.post("/wallet/job", {
    period,
    maxPeriod,
    deviation,
    gasPriceLimit,
    swapGasLimit,
    blockWait,
    blockTime,
    priorityFee,
    addOnFee,
    tokenAddresses,
    ethPercent,
    tokenPercent,
    walletIds,
    retries,
    env,
    toApprove,
    tokenAddress,
    spenderAddress,
    amount,
    speed,
    type: "Nonce Swap",
    module: "nonceModule",
  });

  return resp.data.success;
};

export const csvNonceSwap = async (
  period,
  maxPeriod,
  deviation,
  gasPriceLimit,
  swapGasLimit,
  blockWait,
  blockTime,
  priorityFee,
  addOnFee,
  csvData,
  retries,
  env,
  toApprove,
  tokenAddress,
  spenderAddress,
  amount,
  speed
) => {
  const resp = await axiosInstance.post("/wallet/job", {
    period,
    maxPeriod,
    deviation,
    gasPriceLimit,
    swapGasLimit,
    blockWait,
    blockTime,
    priorityFee,
    addOnFee,
    csvData,
    retries,
    env,
    toApprove,
    tokenAddress,
    spenderAddress,
    amount,
    speed,
    type: "CSV Nonce Swap",
    module: "nonceModule",
  });

  return resp.data.success;
};

export const launch = async (
  tokenAddress,
  pairAddress,
  tokenAmounts,
  priorityFee,
  priorityFeeOthers,
  addOnFee,
  addOnFeeOthers,
  enableTradingGasLimit,
  swapGasLimit,
  walletIds,
  readFunction,
  readParameters,
  writeFunction,
  writeParameters,
  tokenAbi,
  waitBlocks,
  primaryPk,
  isPrioritized,
  env,
  simulationOnly,
  overFundPercent
) => {
  const resp = await axiosInstance.post("/wallet/job", {
    tokenAddress,
    pairAddress,
    tokenAmounts,
    priorityFee,
    priorityFeeOthers,
    addOnFee,
    addOnFeeOthers,
    enableTradingGasLimit,
    swapGasLimit,
    walletIds,
    readFunction,
    readParameters,
    writeFunction,
    writeParameters,
    tokenAbi,
    waitBlocks,
    primaryPk,
    isPrioritized,
    env,
    simulationOnly,
    overFundPercent,
    type: "Launch",
    module: "launchModule",
  });

  return resp.data.success;
};

// Todo for new frontend: update frontend to replace stopLaunch and stopSwapLaunch with this
export const stopJob = async (id, module) => {
  const resp = await axiosInstance.put(`/wallet/job/${id}`, { module });

  return resp.data.success;
};

export const stopSwapLaunch = async () => {
  const resp = await axiosInstance.put("/wallet/swap");

  return resp.data.success;
};

export const stopLaunch = async () => {
  const resp = await axiosInstance.put("/wallet/launch");

  return resp.data.success;
};

export const executePrivateTx = async (
  tokenAddress,
  priorityFee,
  addOnFee,
  writeFunction,
  writeParameters,
  readFunction,
  readParameters,
  tokenAbi,
  primaryPk,
  enableTradingGasLimit,
  waitBlocks,
  env
) => {
  const resp = await axiosInstance.post("/wallet/job", {
    tokenAddress,
    priorityFee,
    addOnFee,
    writeFunction,
    writeParameters,
    readFunction,
    readParameters,
    tokenAbi,
    primaryPk,
    enableTradingGasLimit,
    waitBlocks,
    env,
    type: "Private",
    module: "privateModule",
  });

  return resp.data.success;
};

export const getTokenInfo = async (ca) => {
  const resp = await axiosInstance.get(`/wallet/token/info/${ca}`);

  return resp.data;
};

export const getNonces = async (chain = "default", env) => {
  const resp = await axiosInstance.get(
    `/wallet/nonce?chain=${chain}&env=${env}`
  );

  return resp.data;
};

export const getTokens = async (addresses) => {
  const resp = await axiosInstance.post("/wallet/balance/token/all", {
    addresses,
  });

  return resp.data.tokens;
};

export const getEthBalances = async (chain = "default", env) => {
  const resp = await axiosInstance.get(
    `/wallet/balance/eth?chain=${chain}&env=${env}`
  );

  return resp.data;
};

export const getTokenBalances = async (ca, chain = "ethereum", env) => {
  const resp = await axiosInstance.get(
    `/wallet/balance/token/${ca}?chain=${chain}&env=${env}`
  );

  return resp.data;
};

export const restartServer = async () => {
  const resp = await axiosInstance.post("/wallet/restart");

  return resp.data;
};

export const getLogs = async () => {
  const resp = await axiosInstance.get("/wallet/logs");

  return resp.data;
};

export const getHistory = async (env) => {
  const resp = await axiosInstance.get(`/wallet/history?env=${env}`);

  return resp.data;
};
