import React, { useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import {
  AccountBalanceWallet,
  History,
  ReplyAll,
  Paid,
  ShoppingCart,
  ChevronLeft,
  Menu,
  SwapVerticalCircle,
  SwapHorizontalCircle,
  RocketLaunch,
  Share,
  Settings,
  Lock,
  SwapHoriz,
  RestartAlt,
  Add,
} from "@mui/icons-material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Logs from "./Logs/Index";
import Accumulation from "./Accumulation/Index";
import Batch from "./Batch/Index";
import BridgeHistory from "./History/Index";
import Liquidation from "./Liquidation/Index";
import Swap from "./Swap/Index";
import Wallet from "./Wallet/Index";
import Launch from "./Launch/Index";
import Distribute from "./Distribute/Index";
import Configuration from "./Configuration/Index";
import Private from "./Private/Index";
import Nonce from "./Nonce/Index";
import { addEnv, getEnvs, restartServer } from "../api/api";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

const defaultTheme = createTheme({
  palette: {
    black: {
      main: "#000000", // Black color
      contrastText: "#fff",
    },
    secondary: {
      main: "#ba000d", // Red color
    },
  },
});

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [dropdownValue, setDropdownValue] = useState("");
  const [envs, setEnvs] = useState([]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleDropdownChange = (event) => {
    router.navigate("/");
    setDropdownValue(event.target.value);
  };

  const fetchEnvs = async () => {
    router.navigate("/");
    const resp = await getEnvs();
    setEnvs(resp);
  };

  const handleAddEnv = async () => {
    const userInput = window.prompt("Please enter new env name:");
    const lowerCaseEnvs = envs.map((env) => env.toLowerCase());
    if (
      userInput !== null &&
      userInput.trim() !== "" &&
      !lowerCaseEnvs.includes(userInput.toLowerCase())
    ) {
      await addEnv(userInput);
      fetchEnvs();
    }
  };

  useEffect(() => {
    fetchEnvs();
  }, []);

  const router = createBrowserRouter([
    {
      path: "config",
      element: <Configuration />,
    },
    {
      path: "logs",
      element: <Logs />,
    },
    {
      path: "accumulation",
      element: <Accumulation env={dropdownValue} />,
    },
    {
      path: "private",
      element: <Private env={dropdownValue} />,
    },
    {
      path: "launch",
      element: <Launch env={dropdownValue} />,
    },
    {
      path: "batch",
      element: <Batch env={dropdownValue} />,
    },
    {
      path: "distribution",
      element: <Distribute env={dropdownValue} />,
    },
    {
      path: "nonce",
      element: <Nonce env={dropdownValue} />,
    },
    {
      path: "history",
      element: <BridgeHistory env={dropdownValue} />,
    },
    {
      path: "liquidation",
      element: <Liquidation env={dropdownValue} />,
    },
    {
      path: "swap",
      element: <Swap env={dropdownValue} />,
    },
    {
      path: "wallet",
      element: <Wallet env={dropdownValue} />,
    },
    {
      path: "*",
      element: (
        <Typography variant="h4">
          {dropdownValue && dropdownValue !== ""
            ? "Select a Module"
            : "Select an Environment"}
        </Typography>
      ),
    },
  ]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} color="black">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <Menu />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Black Magic
            </Typography>
            <IconButton color="inherit" onClick={() => restartServer()}>
              <RestartAlt />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeft />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <React.Fragment>
              <FormControl style={{ margin: 10, width: "200px" }}>
                <InputLabel id="dropdown-label">Environment</InputLabel>
                <Select
                  labelId="dropdown-label"
                  value={dropdownValue}
                  onChange={handleDropdownChange}
                >
                  {envs.map((env) => (
                    <MenuItem value={env}>{env}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ListItemButton onClick={() => handleAddEnv()}>
                <ListItemIcon>
                  <Add />
                </ListItemIcon>
                <ListItemText primary="Add Env" />
              </ListItemButton>

              <Divider />

              {dropdownValue && dropdownValue !== "" && (
                <>
                  <ListSubheader component="div" inset>
                    Modules
                  </ListSubheader>
                  <ListItemButton onClick={() => router.navigate("/wallet")}>
                    <ListItemIcon>
                      <AccountBalanceWallet />
                    </ListItemIcon>
                    <ListItemText primary="Wallet" />
                  </ListItemButton>
                  <ListItemButton onClick={() => router.navigate("/launch")}>
                    <ListItemIcon>
                      <RocketLaunch />
                    </ListItemIcon>
                    <ListItemText primary="Launch" />
                  </ListItemButton>
                  <ListItemButton onClick={() => router.navigate("/private")}>
                    <ListItemIcon>
                      <Lock />
                    </ListItemIcon>
                    <ListItemText primary="Private" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => router.navigate("/accumulation")}
                  >
                    <ListItemIcon>
                      <ShoppingCart />
                    </ListItemIcon>
                    <ListItemText primary="Accumulation" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => router.navigate("/liquidation")}
                  >
                    <ListItemIcon>
                      <Paid />
                    </ListItemIcon>
                    <ListItemText primary="Liquidation" />
                  </ListItemButton>
                  <ListItemButton onClick={() => router.navigate("/batch")}>
                    <ListItemIcon>
                      <ReplyAll />
                    </ListItemIcon>
                    <ListItemText primary="Batch Collection" />
                  </ListItemButton>
                  <ListItemButton onClick={() => router.navigate("/nonce")}>
                    <ListItemIcon>
                      <SwapHoriz />
                    </ListItemIcon>
                    <ListItemText primary="Nonce Swap" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => router.navigate("/distribution")}
                  >
                    <ListItemIcon>
                      <Share />
                    </ListItemIcon>
                    <ListItemText primary="Distribution" />
                  </ListItemButton>
                  <ListItemButton onClick={() => router.navigate("/swap")}>
                    <ListItemIcon>
                      <SwapVerticalCircle />
                    </ListItemIcon>
                    <ListItemText primary="Swap" />
                  </ListItemButton>
                  <ListItemButton onClick={() => router.navigate("/history")}>
                    <ListItemIcon>
                      <SwapHorizontalCircle />
                    </ListItemIcon>
                    <ListItemText primary="Bridging History" />
                  </ListItemButton>
                  <ListSubheader component="div" inset>
                    Global
                  </ListSubheader>
                  <ListItemButton onClick={() => router.navigate("/logs")}>
                    <ListItemIcon>
                      <History />
                    </ListItemIcon>
                    <ListItemText primary="Logs" />
                  </ListItemButton>
                  <ListItemButton onClick={() => router.navigate("/config")}>
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Environment" />
                  </ListItemButton>
                </>
              )}
            </React.Fragment>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <RouterProvider router={router} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
