import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Paper } from "@mui/material";
import { getConfiguration, updateConfiguration } from "../../api/api";
import { toast } from "react-toastify";

const fieldMapping = [
  { label: "RPC URL (Active Chain)", key: "RPC_URL" },
  { label: "RPC URL Backup", key: "RPC_URL_BACKUP" },
  { label: "WebSocket RPC (Active Chain)", key: "WSS_RPC" },
  { label: "Uniswap Router Contract Address", key: "CONTRACT_ADDRESS_ROUTER" },
  { label: "RPC URL for ETH", key: "RPC_URL_ETH" },
  { label: "RPC URL for Polygon", key: "RPC_URL_POLYGON" },
  { label: "RPC URL for Binance", key: "RPC_URL_BINANCE" },
  { label: "RPC URL for Base", key: "RPC_URL_BASE" },
  { label: "RPC URL for Fantom", key: "RPC_URL_FANTOM" },
  { label: "RPC URL for Optimism", key: "RPC_URL_OPTIMISM" },
  { label: "RPC URL for Avalanche", key: "RPC_URL_AVAX" },
  { label: "MongoDB Database URL", key: "DB_URL" },
  { label: "RocketX API Key", key: "ROCKETX_API_KEY" },
  { label: "1Inch API Key", key: "Inch_API_KEY" },
];

const Configuration = () => {
  const [envVars, setEnvVars] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchEnv = async () => {
    try {
      setLoading(true);
      const envV = await getConfiguration();
      setEnvVars(envV);
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch environment variables from the backend
    fetchEnv();
  }, []);

  const handleChange = (key, value) => {
    setEnvVars({ ...envVars, [key]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateConfiguration(envVars);
      toast('Successfully Updated!', {
        type: "success",
      });
      fetchEnv();
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h4" mb={2}>
        Configuration
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {fieldMapping.map((field) => (
            <TextField
              key={field.key}
              label={field.label}
              value={envVars[field.key] || ""}
              onChange={(e) => handleChange(field.key, e.target.value)}
              fullWidth
              margin="normal"
              multiline
              variant="outlined"
              InputProps={{
                style: { resize: "vertical" },
              }}
            />
          ))}
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={loading}
            fullWidth
            style={{ marginTop: "10px" }}
          >
            Save Changes
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default Configuration;
